// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#quick-panel-sidenav {
  width: 700px;
  min-width: 700px;
  max-width: 700px;
  z-index: 99;
  top: 64px;
  max-height: calc(100vh - 64px);
}
#quick-panel-sidenav #quick-panel {
  height: 100%;
}
#quick-panel-sidenav #quick-panel #quick-panel-tabs {
  height: 100%;
}
#quick-panel-sidenav #quick-panel md-content {
  height: 100%;
}
#quick-panel-sidenav #quick-panel md-content md-tabs {
  height: 100%;
}
#quick-panel-sidenav #quick-panel md-content md-tabs md-tabs-wrapper {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
#quick-panel-sidenav #quick-panel md-content md-tabs md-tabs-content-wrapper md-tab-content {
  height: 100%;
  overflow: hidden;
}
#quick-panel-sidenav #quick-panel md-content md-tabs md-tabs-content-wrapper md-tab-content > div {
  overflow: hidden;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/quick-panel/quick-panel.scss"],"names":[],"mappings":"AAAA;EAGI,YAFkB;EAGlB,gBAHkB;EAIlB,gBAJkB;EAKlB,WAAA;EACA,SAAA;EACA,8BAAA;AADJ;AAEI;EACI,YAAA;AAAR;AACQ;EACI,YAAA;AACZ;AACQ;EACI,YAAA;AACZ;AAAY;EACI,YAAA;AAEhB;AADgB;EACI,qCAAA;EACA,4CAAA;AAGpB;AAAoB;EACI,YAAA;EACA,gBAAA;AAExB;AADwB;EACI,gBAAA;EACA,YAAA;AAG5B","sourcesContent":["#quick-panel-sidenav {\r\n    $quickPanelWidth: 700px;\r\n\r\n    width: $quickPanelWidth;\r\n    min-width: $quickPanelWidth;\r\n    max-width: $quickPanelWidth;\r\n    z-index: 99;\r\n    top: 64px;\r\n    max-height: calc(100vh - 64px);\r\n    #quick-panel {\r\n        height: 100%;\r\n        #quick-panel-tabs {\r\n            height: 100%;\r\n        }\r\n        md-content {\r\n            height: 100%;\r\n            md-tabs {\r\n                height: 100%;\r\n                md-tabs-wrapper {\r\n                    background-color: rgba(0, 0, 0, 0.03);\r\n                    border-bottom: 1px solid rgba(0, 0, 0, 0.06);\r\n                }\r\n                md-tabs-content-wrapper {\r\n                    md-tab-content {\r\n                        height: 100%;\r\n                        overflow: hidden;\r\n                        > div {\r\n                            overflow: hidden;\r\n                            height: 100%;\r\n                        }\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
