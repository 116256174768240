import * as angular from 'angular';
import { SWANConstants } from '@common/SWANConstants';
import { BaseController } from 'src/app/base.controller';
import { PermissionService } from '@services/permission.service';

export class QuickPanelController extends BaseController {
  private _mdSidenav: angular.material.ISidenavService;
  private _rootScope: angular.IRootScopeService;

  public alertReloadCount = 0;
  public alertRefreshSeconds = SWANConstants.alertRefreshMinutes * 60;
  public minutes: number;
  public seconds: number;
  public isQuickPanelOpen = false;

  constructor(
    $mdSidenav: angular.material.ISidenavService,
    $rootScope: angular.IRootScopeService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdSidenav = $mdSidenav;
    this._rootScope = $rootScope;

    $scope.$watch('vm.isQuickPanelOpen', (value) => {
      if (value == false) {
        console.log('refresh alert count');
        this._rootScope.$broadcast('refreshAlertCount');
      }
    });
  }

  $onInit() {
    this.scope.$on('quick-panel-sidenav', () => {
      this._mdSidenav('quick-panel-sidenav').open();
      this.alertReloadCount++;
    });
  }

  public onAlertPageLoaded() {
    console.log('quick panel loaded');
    this._rootScope.$broadcast('refreshAlertCount');
  }

  public onRefreshAlertPage() {
    this.alertReloadCount++;
    this._rootScope.$broadcast('refreshAlertCount');
  }
}

angular.module('app.quickPanel').controller('QuickPanelController', QuickPanelController);
