import * as angular from 'angular';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class WakeSleptAlertDialogController extends BaseController {
  public sleepUntil: Date;
  public sleepReason: string;
  public minimumDate: Date;
  public isUpdating = false;

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  private alertId: number;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    alert: fuse.quickPanelAlertDto,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._http = $http;
    this._languageService = LanguageService;
    this._dayNumberService = DayNumberService;

    this.alertId = alert.id;
    this.sleepUntil = DayNumberService.convertYMDToLocaleDate(alert.sleepUntilYMD);
    this.sleepReason = alert.sleepReason;

    this.minimumDate = DayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId);
  }

  public updateAlert() {
    const body = {
      alertId: this.alertId,
      sleepUntilDayNumber: this._dayNumberService.convertCalendarDateToLocaleDayNumber(this.sleepUntil),
      sleepReason: this.sleepReason,
      browserTimezone: this._dayNumberService.getBrowserTimezone(),
    } as fuse.updateAlertDto;
    this.isUpdating = true;
    this._http
      .post(CommonHelper.getApiUrl('quickpanel/updateAlert'), body)
      .then(
        (res) => {
          this._mdDialog.hide(true);
        },
        () => {
          this._languageService.whoops();
        },
      )
      .finally(() => {
        this.isUpdating = false;
      });
  }

  public wakeAlert() {
    const body = {
      alertId: this.alertId,
    } as fuse.updateAlertDto;
    this.isUpdating = true;
    this._http
      .post(CommonHelper.getApiUrl('quickpanel/updateAlert'), body)
      .then(
        (res) => {
          this._mdDialog.hide(true);
        },
        () => {
          this._languageService.whoops();
        },
      )
      .finally(() => {
        this.isUpdating = false;
      });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }
}

angular.module('app.quickPanel').controller('WakeSleptAlertDialogController', WakeSleptAlertDialogController);
