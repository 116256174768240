import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { BaseController } from 'src/app/base.controller';
export class DismissAlertsDialogController extends BaseController {
  public alerts: fuse.quickPanelAlertDto[];
  public isDismissing = false;

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    alerts: fuse.quickPanelAlertDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._languageService = LanguageService;

    this.alerts = alerts;
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public dismissAlerts() {
    const alertIds = this.alerts.map((a) => a.id);
    this.isDismissing = true;
    this._http
      .post(CommonHelper.getApiUrl('quickpanel/dismissAlerts'), alertIds)
      .then(
        () => {
          this._mdDialog.hide(true);
        },
        () => {
          this._languageService.whoops();
        },
      )
      .finally(() => {
        this.isDismissing = false;
      });
  }
}

angular.module('app.quickPanel').controller('DismissAlrtsDialogController', DismissAlertsDialogController);
