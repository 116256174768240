import * as angular from 'angular';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { AssetClassEnum } from '@indicina/swan-shared/enums/AssetClassEnum';
import { SWANConstants } from '@common/SWANConstants';
import { TableControl } from '@common/helpers/TableControl';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { DismissAlertsDialogController } from './dismiss-alerts-dialog.controller';
import { SleepAlertsDialogController } from './sleep-alerts-dialog.controller';
import { WakeSleptAlertDialogController } from './wake-slept-alert-dialog.controller';
import { BaseController } from 'src/app/base.controller';

class QuickPanelAlertComponent implements angular.IComponentOptions {
  bindings = {
    reloadCount: '<',
    onPageLoaded: '&',
    onRefreshPage: '&',
  };
  controller = QuickPanelAlertController;
  controllerAs = 'vm';
  templateUrl = 'src/app/quick-panel/quick-panel-alert.html';
}

class QuickPanelAlertController extends BaseController {
  private _rootScope: angular.IRootScopeService;
  private _http: angular.IHttpService;
  private _state: angular.ui.IStateService;
  private _mdDialog: angular.material.IDialogService;
  private _languageService: LanguageService;

  private onPageLoaded: Function;
  private onRefreshPage: Function;
  public isIncludeWarnings = false;
  public isIncludeSleptAlerts = false;
  public alertTypes = [] as fuse.alertTypeDto[];
  public selectedAlertTypes: number[];
  private alerts = [] as fuse.quickPanelAlertDto[];
  public filteredAlerts: fuse.quickPanelAlertDto[];
  public typeFilter = '';
  public assetFilter = '';
  public reasonFilter = '';
  public isSelectAllAlerts = false;
  public sleptAlertsCount: number;
  public tableControl = new TableControl({ pageSize: 0 });
  public isPinned = false;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $rootScope: angular.IRootScopeService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._rootScope = $rootScope;
    this._state = $state;
    this._languageService = LanguageService;
  }

  $onInit() {
    this.alertTypes = SWANConstants.alertTypes; // in constructor, the auth may not ready
    this.scope.$watchGroup(['vm.typeFilter', 'vm.assetFilter', 'vm.reasonFilter'], () => {
      if (this.alerts.length) {
        this.filterAlerts();
      }
    });
  }

  $onChanges(changes) {
    if (changes.reloadCount?.currentValue > 0) {
      if (this.alertTypes == null) {
        this.alertTypes = SWANConstants.alertTypes; // in constructor, the auth may not ready
      }
      if (this.selectedAlertTypes == null) {
        this.selectedAlertTypes = this.alertTypes.map((a) => a.id) as number[];
      }

      this.getAlerts();
    }
  }

  private getAlerts() {
    this._http.get(CommonHelper.getApiUrl('quickpanel/getAlerts')).then((result) => {
      const oldAlerts = angular.copy(this.alerts);
      this.alerts = result.data as fuse.quickPanelAlertDto[];
      this.alerts.forEach((alert) => {
        if (oldAlerts.some((a) => a.id == alert.id && a.isSelected)) {
          alert.isSelected = true;
        }
      });
      this.tableControl.pageSize = this.alerts.length;
      this.filterAlerts();
      this.onPageLoaded();
    });
  }

  public refreshAlerts() {
    this.onRefreshPage();
  }

  public filterAlerts() {
    if (this.alerts) {
      this.filteredAlerts = this.alerts.filter((a) => a.type.toLowerCase().indexOf(this.typeFilter.toLowerCase()) > -1);
      this.filteredAlerts = this.filteredAlerts.filter((a) => a.assetName.toLowerCase().indexOf(this.assetFilter.toLowerCase()) > -1);
      this.filteredAlerts = this.filteredAlerts.filter((a) => a.reason.toLowerCase().indexOf(this.reasonFilter.toLowerCase()) > -1);
      this.filteredAlerts = this.filteredAlerts.filter((a) => this.selectedAlertTypes.some((b) => b == a.typeId));
      this.sleptAlertsCount = this.filteredAlerts.filter((a) => a.isSlept).length;
      if (this.isIncludeSleptAlerts == false) {
        this.filteredAlerts = this.filteredAlerts.filter((a) => a.isSlept == false);
      }
      this.isSelectAllAlerts = this.filteredAlerts?.every((a) => a.isSelected) ?? false;
    }
  }

  public clearFilter(): void {
    this.assetFilter = '';
    this.typeFilter = '';
    this.reasonFilter = '';
  }

  public openDismissAlertsDialog() {
    this._mdDialog
      .show({
        controller: DismissAlertsDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/quick-panel/dismiss-alerts-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          alerts: this.filteredAlerts.filter((a) => a.isSelected),
        },
      })
      .then((res) => {
        if (res) {
          this.getAlerts();
          this._rootScope.$broadcast('refreshAlertCount');
        }
      });
  }

  public openSleepAlertsDialog() {
    this._mdDialog
      .show({
        controller: SleepAlertsDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/quick-panel/sleep-alerts-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          alerts: this.filteredAlerts.filter((a) => a.isSelected),
        },
      })
      .then((res) => {
        if (res) {
          this.getAlerts();
        }
      });
  }

  public wakeUpAlert(alert: fuse.quickPanelAlertDto) {
    this._mdDialog
      .show({
        controller: WakeSleptAlertDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/quick-panel/wake-slept-alert-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          alert: alert,
        },
      })
      .then((res) => {
        if (res) {
          this.getAlerts();
        }
      });
  }

  public selectAllAlerts() {
    if (this.isSelectAllAlerts) {
      this.alerts.forEach((a) => {
        if (this.filteredAlerts.some((b) => b.id == a.id)) {
          a.isSelected = true;
        } else {
          a.isSelected = false;
        }
      });
    } else {
      this.alerts.forEach((a) => {
        a.isSelected = false;
      });
    }
  }

  public selectAlert() {
    if (this.filteredAlerts.some((a) => a.isSelected == false)) {
      this.isSelectAllAlerts = false;
      return;
    } else {
      this.isSelectAllAlerts = true;
    }
  }

  public toggleSleptAlerts(): void {
    this.isIncludeSleptAlerts = !this.isIncludeSleptAlerts;
    this.filterAlerts();
  }

  public gotoAsset(assetId: number) {
    const assetAlert = this.alerts.find((a) => a.assetId == assetId);

    if (assetAlert == null) {
      this._languageService.info('PROJ.SITE_EQUIP_NOT_FOUND');
      return;
    }

    if (assetAlert.assetClassId == AssetClassEnum.Site) {
      LocalStorageUtils.updateContextData((context) => {
        context.siteId = assetId;
      });

      this._state.go('app.account.sites.detail', { id: assetId, viewSchedule: true });
    } else {
      LocalStorageUtils.updateContextData((context) => {
        context.assetId = assetId;
      });

      this._state.go('app.account.equipments.detail', { id: assetId });
    }
  }

  public dismissAlert(alert: fuse.quickPanelAlertDto): void {
    this._mdDialog
      .show({
        controller: DismissAlertsDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/quick-panel/dismiss-alerts-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          alerts: [alert],
        },
      })
      .then((res) => {
        if (res) {
          this.getAlerts();
          this._rootScope.$broadcast('refreshAlertCount');
        }
      });
  }

  public sleepAlert(alert: fuse.quickPanelAlertDto): void {
    this._mdDialog
      .show({
        controller: SleepAlertsDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/quick-panel/sleep-alerts-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          alerts: [alert],
        },
      })
      .then((res) => {
        if (res) {
          this.getAlerts();
        }
      });
  }

  public togglePin() {
    this.isPinned = !this.isPinned;
  }
}

angular.module('app.core').component('quickPanelAlert', new QuickPanelAlertComponent());
