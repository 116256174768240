import * as angular from 'angular';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { BaseController } from 'src/app/base.controller';

export class SleepAlertsDialogController extends BaseController {
  public alerts: fuse.quickPanelAlertDto[];
  public isSleeping = false;

  public sleepUntil: Date;
  public sleepReason: string;
  public minimumDate: Date;

  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    alerts: fuse.quickPanelAlertDto[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;

    this.alerts = alerts;

    this.minimumDate = new Date().addDays(1);
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public sleepAlerts() {
    const selectedAlertIds = this.alerts.map((a) => a.id);
    this.isSleeping = true;
    const body = {
      alertIds: selectedAlertIds,
      sleepUntilDayNumber: this._dayNumberService.convertCalendarDateToLocaleDayNumber(this.sleepUntil),
      sleepReason: this.sleepReason,
      browserTimezone: this._dayNumberService.getBrowserTimezone(),
    } as fuse.sleepAlertsDto;
    this._http
      .post(CommonHelper.getApiUrl('quickpanel/sleepAlerts'), body)
      .then(
        () => {
          this._mdDialog.hide(true);
        },
        () => {
          this._languageService.whoops();
        },
      )
      .finally(() => {
        this.isSleeping = false;
      });
  }
}

angular.module('app.quickPanel').controller('SleepAlertsDialogController', SleepAlertsDialogController);
