// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sleep-alerts-dialog md-input-container {
  margin: 0 0 8px;
  padding: 0;
}
#sleep-alerts-dialog md-input-container .md-errors-spacer {
  min-height: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/quick-panel/sleep-alerts-dialog.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,UAAA;AAAR;AACQ;EAEI,0BAAA;AAAZ","sourcesContent":["#sleep-alerts-dialog {\r\n    md-input-container {\r\n        margin: 0 0 8px;\r\n        padding: 0;\r\n        .md-errors-spacer {\r\n            //display: inline;\r\n            min-height: 0px !important;\r\n        }    \r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
